import { ModalDialog } from "./webComponents/modalDialog";

window.onload = () => {
	let loginForms = document.getElementsByName("login-form");
	let reallySubmit = false;

	loginForms.forEach(loginForm => {
		loginForm.addEventListener('submit', async (ev: Event) => {
			if (reallySubmit) {
				return;
			}

			ev.preventDefault();

			//If the authenticator field is empty and invisible, check if the email address has an authentication code attached
			let authenticationCode: string;
			let email: string;

			let section = loginForm.parentElement;
			var emailElement = section.querySelector('[name="UserName"]') as HTMLInputElement;
			email = emailElement.value;
			authenticationCode = (section.querySelector('[name="AuthenticationCode"]') as HTMLInputElement).value;

			//We'll now send the email address to the server to check if we have 2FA for this email address
			let result = await fetch(section.dataset.url, {
				method: 'POST',
				body: JSON.stringify({ email: email }),
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			});

			let jsonResult = await result.json();

			let resultValue: boolean = jsonResult;

			//resultValue will be true if we need to request a TOTP code
			if (resultValue) {
				//Reveal the authentication code input box
				(section.querySelector('[name="AuthenticationCode"]') as HTMLInputElement)
					.parentElement.parentElement.parentElement
					.classList.remove("hidden");

				reallySubmit = true;

				return;
			}

			reallySubmit = true;
			section.getElementsByTagName("form")[0].submit();
		});
	});

	document.getElementsByName("openOnMobileButton").forEach((value: HTMLElement) => {
		
		value.addEventListener('click', (ev: MouseEvent) => {
			let dialog = document.getElementById("open-mobile-dialog") as ModalDialog;

			dialog.invisible = false;
		});
	})
};